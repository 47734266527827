.nav-bar-background {
  background-color: #0ca9cc !important;
}

@media (min-width: 576px) {
  .nav-bar-background {
    min-height: 100vh;
  }
}

@media (min-width: 1920px) {
  .nav-bar-background {
    padding-left: 10% !important;
  }
}

.nav-brand-title {
  font-size: 20px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: white !important;
}

.nav-link {
  font-size: 16px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: white !important;
  padding: 8px !important;
}

.nav-link-active {
  background-color: #ecedf2;
  color: black !important;
  font-weight: 700;
}

.nav-link-name {
  color: black !important;
}

.date-picker {
  text-align: center!important;
}