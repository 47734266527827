.page-container {
  margin: 50px;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus,
.btn-primary:active:focus {
  background-color: #0ca9cc !important;
  border-color: #0ca9cc !important;
}

.btn-selection {
  background-color: rgb(246, 132, 1) !important;
  border-color: rgb(246, 132, 1) !important;
}

.row-margin {
  margin-top: 15px;
}

.notification-hint-padding {
  padding-left: 20px;
}

.preview-border {
  border: 1px dotted #0ca9cc;
}

.preview-error {
  color: #dc3545;
}

.tag-error-text {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.overflow {
  overflow-wrap: break-word;
}

.alert-align {
  margin-left: 200px !important;
}

.delete-entry {
  font-size: 13px !important;
}

.new-colleague-col {
  /* border: none !important; */
  border-left: none !important;
  border-right: none !important;
}

.warning-tip {
  color: grey;
}

.label-alignment {
  margin-top: 8px !important;
  margin-bottom: 10px !important;
}

tr {
  height: 56px !important;
}

.connected_status_icon {
  width: 20px;
  height: auto;
}

.start-date-picker-label {
  text-align: end!important;
}

.end-date-picker-label {
  text-align: start!important;
}

.review-text-style {
  font-size: 16px;
  font-weight: 500;
}

.bg-progress-bar {
  background-color: #0ca9cc !important;
}

.tag-preview {
  padding: 8px 12px;
  border-radius: 8px;
  color: white;
}